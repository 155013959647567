/*
 * Planetary — A Battletech Campaign Manager
 * Copyright © 2020 Michael Gratton <mike@vee.net>
 *
 * This software is licensed under the GNU Affero General Public License,
 * version 3 or later. See the COPYING file in this for more information.
 */

import api from '../../lib/api';
import net from '../../lib/net';

const REQUESTED = "requested";
const SUCCEEED = "succeeded";
const FAILED = "failed";
const SIGNED_OUT = "signedOut";

const STORAGE_TOKEN = "auth-token";
const STORAGE_TYPE = "auth-type";

export const Status = Object.freeze({
  "NOT_INITALISED": 1,
  "NOT_AUTHENTICATED": 2,
  "REQUESTED": 3,
  "VALID": 4,
  "FAILED": 5,
});

const namespaced = true;

const state = {
  status: Status.NOT_INITALISED
};

const getters = {
  isInitialised: state => (
    state.status != Status.NOT_INITALISED
  ),
  isAuthenticated: state => (
    state.status == Status.VALID
  ),
  isUnauthenticated: state => (
    state.status == Status.NOT_AUTHENTICATED ||
    state.status == Status.FAILED
  ),
  status: state => state.status
};

const actions = {
  async init({commit, dispatch}) {
    // Don't set enabled here since other modules won't be able to
    // tell the difference between in-progress init and in-progress
    // signing in
    if (reloadAuthStorage()) {
      try {
        await dispatch('account/update', null, { root: true });
        commit(SUCCEEED);
      } catch (err) {
        await dispatch('account/clear', null, { root: true });
        clearAuthStorage();
        commit(FAILED, err);
        throw err;
      }
    } else {
      commit(SIGNED_OUT);
    }
  },
  async signIn({commit, dispatch}, {email, password}) {
    commit(REQUESTED);
    try {
      const res = await api.auth.signIn(email, password);
      updateAuthStorage(res);
      await dispatch('account/update', null, { root: true });
      commit(SUCCEEED);
    } catch (err) {
      await dispatch('account/clear', null, { root: true });
      clearAuthStorage();
      commit(FAILED, err);
      throw err;
    }
  },
  async signOut({commit, dispatch}) {
    await dispatch('account/clear', null, { root: true });
    clearAuthStorage();
    commit(SIGNED_OUT);
  }
};

const mutations = {
  [REQUESTED]: state => {
    state.status = Status.REQUESTED;
  },
  [SUCCEEED]: state => {
    state.status = Status.VALID;
  },
  [SIGNED_OUT]: state => {
    state.status = Status.NOT_AUTHENTICATED;
  },
  [FAILED]: (state, e) => {
    state.status = Status.FAILED;
  },
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
};

function updateAuthStorage({token_type, access_token}) {
  localStorage.setItem(STORAGE_TYPE, token_type);
  localStorage.setItem(STORAGE_TOKEN, access_token);
  net.defaults.headers.set('Authorization', `${token_type} ${access_token}`);
}

function reloadAuthStorage() {
  const token_type = localStorage.getItem(STORAGE_TYPE);
  const access_token = localStorage.getItem(STORAGE_TOKEN);
  const reload = (token_type != null && access_token != null);
  if (reload) {
    net.defaults.headers.set('Authorization', `${token_type} ${access_token}`);
  }
  return reload;
}

function clearAuthStorage() {
  localStorage.removeItem(STORAGE_TYPE);
  localStorage.removeItem(STORAGE_TOKEN);
  net.defaults.headers.delete('Authorization');
}
