/*
 * Planetary — A Battletech Campaign Manager
 * Copyright © 2020 Michael Gratton <mike@vee.net>
 *
 * This software is licensed under the GNU Affero General Public License,
 * version 3 or later. See the COPYING file in this for more information.
 */

import Vue from 'vue';
import VueRouter from 'vue-router';

import store from './store';

import App from './App.vue';
import Home from './components/Home.vue';

Vue.use(VueRouter);

const routes = [{
  path: '/',
  name: 'home',
  component: Home,
  meta: { isPublic: true }
}, {
  path: '/new-command',
  name: 'new-command',
  component: () => import('./components/NewCommand.vue'),
}, {
  path: '/command',
  name: 'command',
  component: () => import('./components/ViewCommand.vue'),
}, {
  path: '/about',
  name: 'about',
  component: () => import('./components/About.vue'),
  meta: { isPublic: true }
}];

const router = new VueRouter({
    mode: 'history',
    routes: routes
});
router.beforeEach((to, from, next) => {
  const classes = window.document.documentElement.classList;
  if (to.matched.some(record => record.meta.isPublic)) {
    classes.add('public');
    next();
  } else if (!store.getters['auth/isAuthenticated']) {
    next({
      path: '/',
      query: { redirect: to.fullPath }
    });
  } else {
    classes.remove('public');
    next();
  }
});

export default router;
