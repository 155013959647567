<!--
 Planetary — A Battletech Campaign Manager
 Copyright © 2020 Michael Gratton <mike@vee.net>

 This software is licensed under the GNU Affero General Public License,
 version 3 or later. See the file COPYING for more information.
-->

<template>
  <div id="sign-in" class="container col-md-6">
    <form @submit.prevent="onSubmit">
      <div class="form-group">
        <label for="email">Your email:</label>
        <input class="form-control"
               type="email"
               v-model.trim="email"
               v-bind:disabled="isInProgress"
               autofocus
               required
               placeholder="you@example.com"
               aria-label="Email addrss">
      </div>

      <div class="form-group">
        <label for="password">Password:</label>
        <input class="form-control"
               type="password"
               v-model="password"
               v-bind:disabled="isInProgress"
               required
               aria-label="Password">
      </div>

      <div class="form-group"
           :class="{ 'd-none': !isRegistering }">
        <label for="confirm">Confirm:</label>
        <div class="input-group mb-3">
          <input class="form-control"
                 type="password"
                 v-model="confirm"
                 v-bind:disabled="isInProgress || !isRegistering"
                 required
                 aria-label="Confirm password">
          <div class="input-group-append">
            <span class="input-group-text">
              <i class="far" :class="confirmClass"></i>
            </span>
          </div>
        </div>
      </div>

      <div class="form-group"
           :class="{ 'd-none': !isRegistering }">
        <label for="name">Your name:</label>
        <input class="form-control"
               type="text"
               v-model.trim="name"
               v-bind:disabled="isInProgress || !isRegistering"
               required
               aria-label="Your name">
      </div>

      <submit-bar v-bind:in-progress="isInProgress"
                  v-bind:disabled="!isComplete">
        <button class="btn btn-primary"
                :class="{ 'd-none': isRegistering }"
                type="submit">Sign In</button>
        <button class="btn btn-secondary mx-2"
                :class="{ 'd-none': isRegistering }"
                @click="isRegistering = true">Register</button>
        <button class="btn btn-primary mx-2"
                :class="{ 'd-none': !isRegistering }"
                type="submit">Register</button>
      </submit-bar>

      <div class="alert alert-primary"
           :class="{ 'd-none': !hasRegistered }"
           role="alert">
          Registration was successful, please log in.
      </div>
      <div class="alert alert-warning"
           :class="{ 'd-none': !signInFailed }"
           role="alert">
          Sign in failed, please check your email and password.
      </div>
      <div class="alert alert-warning"
           :class="{ 'd-none': !registerFailed }"
           role="alert">
          This email address has already been registered.
      </div>
    </form>
  </div>
</template>

<script>
import { Status } from '../store/modules/auth';
import { AuthenticationError, ServerError } from '../lib/net';
import SubmitBar from './SubmitBar.vue';

export default {
  name: "sign-in",
  components: {
    SubmitBar
  },
  data: function() {
    return {
      email: "",
      password: "",
      confirm: "",
      name: "",
      isRegistering: false,
      hasRegistered: false,
      hasRegisterFailed: false,
    };
  },
  computed: {
    isComplete() {
      let complete = (this.email != "" && this.password != "");
      if (this.isRegistering) {
        complete = complete && this.isPasswordConfirmed && this.name != "";
      };
      return complete;
    },
    isInProgress() {
      return this.$store.getters['auth/status'] == Status.REQUESTED;
    },
    signInFailed() {
      return (
        this.$store.getters['isApiOkay'] &&
        this.$store.getters['auth/status'] == Status.FAILED
      );
    },
    registerFailed() {
      return this.hasRegisterFailed;
    },
    isPasswordConfirmed() {
      return this.confirm.length > 0 && this.password == this.confirm
    },
    confirmClass: function() {
      return {
        'fa-check-circle': this.isPasswordConfirmed,
        'fa-times-circle': !this.isPasswordConfirmed
      }
    },
  },
  methods: {
    onSubmit: function (e) {
      if (!this.isRegistering) {
        this.$store.dispatch("auth/signIn", this).catch(err => {});
      } else if (this.isPasswordConfirmed) {
        this.$store.dispatch('account/register', this).then(account => {
          this.isRegistering = false;
          this.hasRegistered = true;
          this.hasRegisterFailed = false;
        }).catch(err => {
          console.log(err);
          if (err instanceof ServerError && err.response.status == 409) {
            this.hasRegisterFailed = true;
          }
        });
      }
    },
  }
};
</script>
