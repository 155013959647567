<!--
 Planetary — A Battletech Campaign Manager
 Copyright © 2020 Michael Gratton <mike@vee.net>

 This software is licensed under the GNU Affero General Public License,
 version 3 or later. See the file COPYING for more information.
-->

<template>
  <div id="home" class="planetary container mt-5 col-lg-5">
    <h1 class="display-4 text-center">
        <i class="fas fa-globe-asia"></i><br>
        Welcome to Planetary
    </h1>
    <p class="lead text-center">Pithy tag line goes here.</p>
    <hr class="my-4">
    <transition name="fade" mode="out-in">
      <div v-if="isWarmingUp"
           key="warming-up"
            class="d-flex align-items-center justify-content-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div v-if="showLogin" key="login">
        <sign-in>
      </div>
      <div v-if="showCreate"  key="create">
        <p class="lead">Create your unit’s command to get started.</p>
        <div class="form-row d-flex flex-row-reverse">
          <button type="button"
                  class="btn btn-primary btn-lg"
                  v-on:click="$router.push({ name: 'new-command'})">Create Now</button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { Status } from '../store/modules/auth';
import SignIn from './SignIn.vue';

export default {
  name: "home",
  components: {
      SignIn
  },
  computed: {
    isWarmingUp() {
      return (
        !this.$store.getters['auth/isInitialised']
      );
    },
    showLogin() {
      return (
        this.$store.getters['auth/isUnauthenticated'] ||
        this.$store.getters['auth/status'] == Status.REQUESTED
      );
    },
    showCreate() {
      return (
        this.$store.getters['auth/isAuthenticated'] &&
        this.$store.getters['unit/currentCommand'] == null
      );
    },
    showCommand() {
      return (
        this.$store.getters['auth/isAuthenticated'] &&
        this.$store.getters['unit/currentCommand'] != null
      );
    }
  },
  watch: {
    showCommand: function (newVal, prevVal) {
      this.$router.push('/command');
    }
  },
};
</script>
