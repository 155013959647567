/*
 * Planetary — A Battletech Campaign Manager
 * Copyright © 2020 Michael Gratton <mike@vee.net>
 *
 * This software is licensed under the GNU Affero General Public License,
 * version 3 or later. See the COPYING file in this for more information.
 */

const defaults = {
  baseUri: new URL(window.location),
  headers: new Map(),
};

const handlers = {
  success: null,
  error: null,
  failure: null,
};

export class AuthenticationError extends Error {
  constructor(response) {
    super(response.statusText);
    this.response = response;
  }
}

export class ServerError extends Error {
  constructor(response) {
    super(response.statusText);
    this.response = response;
  }
}

export default {
  defaults: defaults,
  handlers: handlers,
  async get(url) {
    const res = await send(url, {
      headers: new Headers(defaults.headers)
    });
    checkResponse(res);
    return res;
  },
  async postJson(url, data) {
    const res = await send(url, {
      method: 'POST',
      headers: new Headers(defaults.headers),
      body: JSON.stringify(data),
    });
    checkResponse(res);
    return res;
  },
  async postForm(url, data) {
    const form = new FormData();
    for (const property in data) {
        form.set(property, data[property]);
    }
    const res = await send(url, {
      method: 'POST',
      headers: new Headers(defaults.headers),
      body: form,
    });
    checkResponse(res);
    return res;
  }
}

async function send(url, options) {
  try {
    return await fetch(new URL(url, defaults.baseUri), options);
  } catch (err) {
    if (handlers.failure != null) {
      handlers.failure(err);
    }
    throw err;
  }
}

function checkResponse(res) {
  if (res.ok) {
    if (handlers.success != null) {
      handlers.success(res);
    }
  } else {
    if (handlers.error != null) {
      handlers.error(res);
    } 

    let err = null;
    if (res.status == 401) {
      err = new AuthenticationError(res);
    } else {
      err = new ServerError(res);
    }
    throw err;
  }
}
