<template>
  <fieldset class="form-row d-flex flex-row-reverse mb-4 align-items-center"
            :disabled="disabled || inProgress">
    <slot></slot>
    <div class="spinner-border spinner-border-sm mx-2"
         :class="{ 'd-none': !inProgress }"
         role="status">
    <span class="sr-only">Loading...</span>
  </fieldset>
</template>

<script>
export default {
  name: "submit-bar",
  props: ['disabled', 'in-progress'],
}
</script>
