<!--
 Planetary — A Battletech Campaign Manager
 Copyright © 2020 Michael Gratton <mike@vee.net>

 This software is licensed under the GNU Affero General Public License,
 version 3 or later. See the file COPYING for more information.
-->

<template>
  <main id="app">
    <transition name="fade" mode="out-in">
      <div class="fixed-top mx-2 my-1" v-if="isAuthenticated">
        <sign-out>
      </div>
    </transition>
    <transition name="slide-down" mode="out-in">
      <div class="fixed-top alert alert-danger rounded-0"
           v-if="isServerError || isNetworkError">
        <p v-if="isServerError" class="mb-0">
          The server having a bad hair day.
          Please try again later.
        </p>
        <p v-if="isNetworkError" class="mb-0">
          Could not connect to the server.
          Please check your network connection and try again.
        </p>
      </div>
    </transition>
    <transition name="fade" mode="out-in">
      <router-view>
    </transition>
  </main>
</template>

<script>
import { ApiStatus } from './store';

import Home from './components/Home.vue';
import SignOut from './components/SignOut.vue';

export default {
  name: "app",
  components: {
      Home,
      SignOut
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['auth/isAuthenticated'];
    },
    isServerError() {
      return this.$store.state.apiStatus == ApiStatus.SERVER_ERROR;
    },
    isNetworkError() {
      return this.$store.state.apiStatus == ApiStatus.NETWORK_ERROR;
    },
  }
};
</script>

<style>
html.public {
  background: fixed no-repeat bottom black url('../static/background.jpg');
}
html.public body {
  background-color: transparent;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity, background;
  transition-timing-function: ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0
}

.slide-down-enter-active,
.slide-down-leave-active {
  transition: height 0.5s ease;
}
.slide-down-enter,
.slide-down-leave-active {
  height: 0;
}

</style>
