/*
 * Planetary — A Battletech Campaign Manager
 * Copyright © 2020 Michael Gratton <mike@vee.net>
 *
 * This software is licensed under the GNU Affero General Public License,
 * version 3 or later. See the COPYING file in this for more information.
 */

import net from './net';

export default {
  auth: {
    signIn(email, password) {
      let data = {
        // OAuth2 requires this be called "username" :(
        username: email,
        password: password
      };
      return net.postForm('/auth/sign-in', data).then(res => res.json());
    }
  },
  account: {
    register(name, email, password) {
      let data = {
        // OAuth2 requires this be called "username" :(
        name: name,
        email: email,
        password: password
      };
      return net.postJson('/account/register', data).then(res => res.json());
    },
    self() {
      return net.get('/account/self').then(res => res.json());
    },
  },
  base: {
    load() {
      return net.get('/base/load').then(res => res.json());
    }
  },
  units: {
    loadCommands() {
      return net.get('/unit/load-commands').then(res => res.json());
    },
    createCommand(name, origin, era) {
      let data = {
        name: name,
        origin: origin,
        era: era
      };
      return net.postJson('/unit/create-command', data).then(res => res.json());
    },
  }
}
