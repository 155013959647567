/*
 * Planetary — A Battletech Campaign Manager
 * Copyright © 2020 Michael Gratton <mike@vee.net>
 *
 * This software is licensed under the GNU Affero General Public License,
 * version 3 or later. See the COPYING file in this for more information.
 */

import api from '../../lib/api';
import unit from '../../lib/unit';

const namespaced = true;

const state = {
  currentCommand: null,
  commands: [],
};

const getters = {
  currentCommand: state => state.currentCommand,
  commands: state => state.commands
};

function dataToCommand(baseState, data) {
  return new unit.Command(
    data.id,
    data.name,
    baseState.origins.get(data.origin),
    baseState.eras.get(data.era),
  );
}

const actions = {
  loadCommands({state, rootState, commit, dispatch}) {
    return api.units.loadCommands().then(res => {
      const commands = [];
      res.forEach((data, i) => {
        commands.push(dataToCommand(rootState.base, data));
      });
      commit('commands', commands);
      commit('currentCommand', (commands.length > 0) ? commands[0] : null);
    });
  },
  createCommand({rootState, commit, dispatch}, {name, origin, era}) {
    api.units.createCommand(name, origin.key, era.year).then(res => {
      const command = dataToCommand(rootState.base, res);
      commit('addCommand', command);
      commit('currentCommand', command);
    });
  },
  clearCommands({rootState, commit, dispatch}) {
    return new Promise((resolve, reject) => {
      commit('currentCommand', null);
      commit('commands', []);
      resolve();
    });
  },
};

const mutations = {
  currentCommand: (state, command) => {
    state.currentCommand = command;
  },
  addCommand: (state, command) => {
    state.commands = state.commands.concat([command]);
  },
  commands: (state, commands) => {
    state.commands = commands;
  },
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
