/*
 * Planetary — A Battletech Campaign Manager
 * Copyright © 2020 Michael Gratton <mike@vee.net>
 *
 * This software is licensed under the GNU Affero General Public License,
 * version 3 or later. See the COPYING file in this for more information.
 */

import Vue from 'vue';
import Vuex from 'vuex';

import account from './modules/account';
import auth from './modules/auth';
import base from './modules/base';
import unit from './modules/unit';

import net from '../lib/net';

Vue.use(Vuex);

export const debug = process.env.NODE_ENV !== 'production';

export const ApiStatus = Object.freeze({
  "UNKNOWN": 1,
  "OK": 2,
  "SERVER_ERROR": 3,
  "NETWORK_ERROR": 4,
});


const store = new Vuex.Store({
  state: {
    apiStatus: ApiStatus.UNKNOWN,
  },
  getters: {
    isApiOkay(state) {
      return state.apiStatus == ApiStatus.OK
    }
  },
  mutations: {
    apiStatus(state, status) {
      state.apiStatus = status;
    },
  },
  modules: {
    account,
    auth,
    base,
    unit,
  },
  strict: debug,
});

net.defaults.baseUri.port = 8000;
net.handlers.success = (res) => {
  store.commit("apiStatus", ApiStatus.OK);
};
net.handlers.error = (res) => {
  if (res.status >= 500 && res.status < 600) {
    console.error("Server error:", res);
    store.commit("apiStatus", ApiStatus.SERVER_ERROR);
  }
};
net.handlers.failure = (res) => {
  console.error("Network failure:", res);
  store.commit("apiStatus", ApiStatus.NETWORK_ERROR);
};

store.dispatch('auth/init');

export default store;
