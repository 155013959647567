/*
 * Planetary — A Battletech Campaign Manager
 * Copyright © 2020 Michael Gratton <mike@vee.net>
 *
 * This software is licensed under the GNU Affero General Public License,
 * version 3 or later. See the file COPYING for more information.
 */

 'use strict';

import 'bootstrap';
import '@fortawesome/fontawesome-free/css/all.css';
import './index.scss';

import Vue from 'vue';

import App from './App.vue';

Vue.config.productionTip = false;
Vue.config.devtools = false;

import store from './store';
import router from './router';

const vm = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
