/*
 * Planetary — A Battletech Campaign Manager
 * Copyright © 2020 Michael Gratton <mike@vee.net>
 *
 * This software is licensed under the GNU Affero General Public License,
 * version 3 or later. See the COPYING file in this for more information.
 */

import api from '../../lib/api';

const namespaced = true;

const state = {
  current: null
};

const getters = {
  current: state => state.current
};

const actions = {
  async register({commit, dispatch}, {name, email, password}) {
    try {
      const res = await api.account.register(name, email, password);
      commit('set', res.account);
      return res.account;
    } catch (err) {
      commit('clear');
      throw err;
    }
  },
  async update({commit, dispatch}) {
    try {
      const res = await api.account.self();
      commit('set', res);
      await dispatch('base/init', null, { root: true });
      await dispatch('unit/loadCommands', null, { root: true });
    } catch (err) {
      commit('clear');
      cosole.log("error registering");
      throw err;
    }
  },
  async set({commit, dispatch}, account) {
    commit('set', account);
  },
  async clear({commit, dispatch}) {
    try {
      await dispatch('unit/clearCommands', null, { root: true });
    } finally {
      commit('clear');
    }
  }
};

const mutations = {
  set: (state, account) => {
    state.current = account;
  },
  clear: state => {
    state.current = null;
  },
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
