<!--
 Planetary — A Battletech Campaign Manager
 Copyright © 2020 Michael Gratton <mike@vee.net>

 This software is licensed under the GNU Affero General Public License,
 version 3 or later. See the file COPYING for more information.
-->

<template>
  <div id="sign-out">
    <form v-on:submit.prevent>
      <div class="form-row d-flex flex-row-reverse mb-4 align-items-center">
        <button class="btn btn-outline-secondary"
                v-on:click="onSignOut">Sign Out</button>
        <span class="mx-2">{{ name }}</span>
      </div>
    </form>
  </div>
</template>

<script>

export default {
  name: "sign-out",
  computed: {
    name() {
      const account = this.$store.getters['account/current'];
      return (account != null) ? account.name : "";
    }
  },
  methods: {
    onSignOut: function (e) {
      if (this.$route.path != '/') {
        this.$router.push('/');
      }
      this.$store.dispatch("auth/signOut");
    }
  }
};
</script>
