/*
 * Planetary — A Battletech Campaign Manager
 * Copyright © 2020 Michael Gratton <mike@vee.net>
 *
 * This software is licensed under the GNU Affero General Public License,
 * version 3 or later. See the COPYING file in this for more information.
 */

import api from '../../lib/api';
import base from '../../lib/base';

const namespaced = true;

const state = {
  eras: new Map(),
  origins: new Map()
};

const getters = {
  eras: state => state.eras,
  origins: state => state.origins
};

const actions = {
  init({commit, dispatch}) {
    return api.base.load().then(res => {
      let eras = new Map();
      res.eras.forEach((data, i) => {
        eras.set(data.year, new base.Era(data.year));
      });
      commit('eras', eras);

      let origins = new Map();
      res.origins.forEach((data, i) => {
        let origin = new base.Origin(data.key, data.label, []);
        data.eras.forEach((year, i) => {
          origin.eras.push(eras.get(year));
        });
        origins.set(origin.key, origin);
      });
      commit('origins', origins);
    });
  },
};

const mutations = {
  eras: (state, eras) => {
    state.eras = eras;
  },
  origins: (state, origins) => {
    state.origins = origins;
  },
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
