/*
 * Planetary — A Battletech Campaign Manager
 * Copyright © 2020 Michael Gratton <mike@vee.net>
 *
 * This software is licensed under the GNU Affero General Public License,
 * version 3 or later. See the COPYING file in this for more information.
 */

 /**
  * A specific period in the BattleTech universe.
  */
 class Era {
   constructor(year) {
     this.year = year;
   }
 }

 /**
  * The technology base of an object
  */
 class Origin {
   constructor(key, label, eras) {
     this.key = key;
     this.label = label;
     this.eras = eras;
   }
 }

export default {
  Era: Era,
  Origin: Origin
}
