/*
 * Planetary — A Battletech Campaign Manager
 * Copyright © 2020 Michael Gratton <mike@vee.net>
 *
 * This software is licensed under the GNU Affero General Public License,
 * version 3 or later. See the COPYING file in this for more information.
 */

 /**
  * A single, self-contained, organised military force.
  */
 class Command {
   constructor(id, name, origin, era) {
     this.id = id
     this.name = name;
     this.origin = origin;
     this.era = era;
     this.organisation = null;
     this.equipment = [];
     this.personnel = [];
   }
 }

export default {
  Command: Command
}
